import React from 'react'
import LeftBar from '../Components/LeftBar'
import NavBar from '../Components/NavBar'
import './Dash.css'
import RightBar from '../Components/RightBar'
import ExperienceBookingComp from './ExperienceBookingComp'

function ExperienceBooking() {
  return (
    <div className='contentMain'> 
      <LeftBar/>
      <div className='theRightOne'> 
        <ExperienceBookingComp/>  
     </div>
     <RightBar/>
    </div>
  )
}

export default ExperienceBooking