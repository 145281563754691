import React, { useRef, useState, useEffect } from 'react'

import {app} from '../firebase.js'
import { getFirestore } from "@firebase/firestore";
import { getAuth, onAuthStateChanged , signOut} from "firebase/auth";
import { collection, doc, setDoc,query, getDocs, deleteDoc, where} from "@firebase/firestore"; 

import {useNavigate } from "react-router-dom";

function AccInfoComp() {

    const db = getFirestore(app);
    const [loggedInUser, setloggedInUser] = useState("");
    const [thenationalID, setnationalID] = useState("");
    const [userPhone, setUserPhone] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [signupdate, setsignupdate] = useState();

    let navigate = useNavigate();
  
      const auth = getAuth();
      onAuthStateChanged(auth, (user) => {
        if (user) {
          const uid = user.uid;
          // ...
  
          const j = query(
            collection(db, "users"),
            where("userId", "==", uid)
          );
          getDocs(j).then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              let fullname = doc.data().fullname;
              let signupdate = doc.data().signupdate;
              let phone = doc.data().phone;
              let email = doc.data().email;
              let nationalID = doc.data().nationalID;
              
              setloggedInUser(fullname)
              setUserPhone(phone)
              setUserEmail(email)

              setnationalID(nationalID)
              setsignupdate(signupdate.toDate().toLocaleString())


            });
        
          });
  
  
        } else {
          // User is signed out
          navigate("/")  
        }
      });



  return (
    <div>

        <div className='logoutCont2' >
            <h6>Full Name</h6>
            <p>{loggedInUser}</p>
        </div>

        <hr />

        <div className='logoutCont2' >
            <h6>Phone Number</h6>
            <p>{userPhone}</p>
        </div>

        <hr />

        <div className='logoutCont2' >
            <h6>ID NUmber/ Passport</h6>
            <p>{thenationalID}</p>
        </div>

        <hr />

        <div className='logoutCont2' >
            <h6>Email Address</h6>
            <p>{userEmail}</p>
        </div>

        <hr />

        <div className='logoutCont2' >
            <h6>Date Joined</h6>
            <p>{signupdate}</p>
        </div>

        <hr />

       

    </div>
  )
}

export default AccInfoComp