import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import './App.css';
///
import Login from './Pages/Login';
import Register from './Pages/Register';
import ResetPassword from './Pages/ResetPassword';
import Explore from './Pages/Explore';
import Experiences from './Pages/Experiences';
import Home from './Pages/Home';
import Express from './Pages/Express';
import Trips from './Pages/Trips';
import Favourites from './Pages/Favourites';
import Profile from './Pages/Profile';
import FlightBooking from './Pages/FlightBooking';
import ExperienceBooking from './Pages/ExperienceBooking';
import ViewBookings from './Pages/ViewBookings';
import AccInfo from './Pages/AccInfo';
import EditProfile from './Pages/EditProfile';
import MakePayment from './Pages/MakePayment';
import ConfirmBooking from './Pages/ConfirmBooking';
import ViewVideo from './Pages/ViewVideo';
import ViewExpTicket from './Pages/ViewExpTicket';
import ViewFlightBookings from './Pages/ViewFlightBookings';
import ViewFlightTicket from './Pages/ViewFlightTicket';


function App() {
  return (
    <BrowserRouter>
          <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/signup" element={<Register />} />
              <Route path="/reset-password" element={<ResetPassword />} />

              <Route path="/home" element={<Home />} />
              <Route path="/experiences" element={<Experiences />} />
              <Route path="/explore" element={<Explore />} />
              <Route path="/express" element={<Express />} />
              <Route path="/trips" element={<Trips />} />
              <Route path="/favourites" element={<Favourites />} />
              <Route path="/profile" element={<Profile />} />

              <Route path="/charter-plane" element={<FlightBooking />} />
              <Route path="/experience-bookings" element={<ExperienceBooking />} />
              <Route path="/view-bookings" element={<ViewBookings />} />

              <Route path="/account-info" element={<AccInfo />} />
              <Route path="/edit-aaccount" element={<EditProfile />} />

              <Route path="/make-payment" element={<MakePayment />} />
              <Route path="/confirm-booking" element={<ConfirmBooking />} />
              <Route path="/play-video" element={<ViewVideo />} />

              <Route path="/view-experience-ticket" element={<ViewExpTicket />} />

              <Route path="/view-flight-bookings" element={<ViewFlightBookings />} />

              <Route path="/view-flight-ticket" element={<ViewFlightTicket />} />
              
            
          </Routes>
      </BrowserRouter> 
  );
}

export default App;
