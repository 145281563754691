import React from 'react'
import LeftBar from '../Components/LeftBar'
import NavBar from '../Components/NavBar'
import './Dash.css'
import RightBar from '../Components/RightBar'
import FlightBookingComp from './FlightBookingComp'

function FlightBooking() {
  return (
    <div className='contentMain'> 
    <LeftBar/>
        <div className='theRightOne'> 
            <FlightBookingComp/>  
        </div>
    <RightBar/>
  </div>
  )
}

export default FlightBooking