import React from 'react'
import LeftBar from '../Components/LeftBar'
import NavBar from '../Components/NavBar'
import './Dash.css'
import RightBar from '../Components/RightBar'
import ExperienceComp from './ExperienceComp'

function Experiences() {
  return (
    <div className='contentMain'> 
      <LeftBar/>
      <div className='theRightOne'> 
        <ExperienceComp/>  
     </div>
     <RightBar/>
    </div>
  )
}

export default Experiences