import React, { useRef, useState, useEffect } from 'react';
import defCover from '../Images/defcover.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlay } from '@fortawesome/free-solid-svg-icons';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faLocationCrosshairs } from '@fortawesome/free-solid-svg-icons';
import { faFingerprint } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import { app } from '../firebase.js';
import { getFirestore } from '@firebase/firestore';
import { collection, query, getDocs, where, getDoc , setDoc, orderBy, deleteDoc} from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import swal from 'sweetalert';
import Table from 'react-bootstrap/Table';
import { doc, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

import { faGauge } from '@fortawesome/free-solid-svg-icons';
import { faHelicopter} from '@fortawesome/free-solid-svg-icons'
import { faCalendar} from '@fortawesome/free-solid-svg-icons'
import { faUsers} from '@fortawesome/free-solid-svg-icons'
import { faWallet} from '@fortawesome/free-solid-svg-icons'




function ViewFlightBookingsComp() {

  const navigate = useNavigate();
  const location = useLocation();
  const db = getFirestore(app);
  const auth = getAuth();
  const [theexpImage, setexpImage] = useState();
  const [theselectedDay, setselectedDay] = useState();
  const [theselectedTime, setselectedTime] = useState();
  const [thenoOfPassangers, setnoOfPassangers] = useState();
  const [theamountPaid, setamountPaid] = useState()
  const [thestatus, setstatus] = useState();
  const [thewhereto, setwhereto] = useState();


  const [theaircraftName, setaircraftName] = useState()
  const [theCapacity, setCapacity] = useState();
  const [theflightDescription, setflightDescription] = useState();
  const [theSpeed, setSpeed] = useState();
  const [theEndurance, setEndurance] = useState();
  const [theTakeOffArea, setTakeOffArea] = useState()

   // Receive data from the previous page
   useEffect(() => {
    const data = location.state?.myData;
    setMyData(data);
    }, [location.state]);
    const [myData, setMyData] = useState(null); // State to store the received data
  
    console.log(myData)


  useEffect(() => {
    // Fetch driver's location from Firebase when myData changes
    const fetchData = async () => {
      if (myData) {
        try {
          const querySnapshot = await getDocs(query(collection(db, 'Bookings'), where('documentId', '==', myData)));
          querySnapshot.forEach(async (doc) => {

            let expImage = doc.data().expImage;
            const selectedDay = doc.data().selectedDay; 
            const selectedTime = doc.data().selectedTime;
            const noOfPassangers = doc.data().noOfPassangers;
            const amountPaid = doc.data().amountPaid;
            const thedocIDbooked = doc.data().thedocIDbooked;
            const status = doc.data().status;
            const whereto =  doc.data().whereto;

            setexpImage(expImage)
            setselectedDay(selectedDay)
            setselectedTime(selectedTime)
            setnoOfPassangers(noOfPassangers)
            setamountPaid(amountPaid)
            setstatus(status)
            setwhereto(whereto)


            try {
              const querySnapshot = await getDocs(query(collection(db, 'flights'), where('docID', '==', thedocIDbooked)));
              querySnapshot.forEach(async (doc) => {
    
              let aircraftName = doc.data().aircraftName;
              const Capacity = doc.data().Capacity;
              const flightDescription = doc.data().flightDescription;
              const Speed = doc.data().Speed;
              const Endurance = doc.data().Endurance;
              const TakeOffArea = doc.data().TakeOffArea;
              

              setaircraftName(aircraftName)  
              setCapacity(Capacity) 
              setflightDescription(flightDescription) 
              setSpeed(Speed)
              setEndurance(Endurance)
              setTakeOffArea(TakeOffArea)
    
              });
            } catch (error) {
              console.error('Error fetching data from Firebase:', error);
            }



          });
        } catch (error) {
          console.error('Error fetching data from Firebase:', error);
        }
      }
    };

    // Initial fetch
    fetchData();
  }, [myData, db]);

  ///



  const formatPrice = (price) => {
    // Convert price to a number if it’s not already
    const num = Number(price);
    // Check if the conversion resulted in NaN
    if (isNaN(num)) {
      return 'N/A'; // Fallback for invalid numbers
    }
    return num.toLocaleString();
  };

  function viewElectronicTicket(){
    navigate('/view-flight-ticket', { state: { myData: myData } });
  }



  return (
   <div className='bookingCenter'>

      <div className='expBookingDiv'>
        <img src={theexpImage} alt="" />
      </div>

      <h4>{theaircraftName}</h4>
      <p className='bookingP'>{theCapacity}</p>
 
      <p className='bookingP'>{theflightDescription}</p>
      <hr />

      <div className='bookingIcons'>
        <FontAwesomeIcon icon={faGauge} className='thebookIcon' />
        <div>
          <h6>Speed/Distane</h6>
          <p>{theSpeed}</p>
        </div>
      </div>

      <div className='bookingIcons'>
        <FontAwesomeIcon icon={faHelicopter} className='thebookIcon' />
        <div>
          <h6>Endurance</h6>
          <p>{theEndurance}</p>
        </div>
      </div>

      <div className='bookingIcons'>
        <FontAwesomeIcon icon={faLocationDot} className='thebookIcon' />
        <div>
          <h6>Boarding Area</h6>
          <p>{theTakeOffArea}</p>
        </div>
      </div>

      <div className='bookingIcons'>
        <FontAwesomeIcon icon={faLocationDot} className='thebookIcon' />
        <div>
          <h6>Destination</h6>
          <p>{thewhereto}</p>
        </div>
      </div>

      <div className='bookingIcons'>
        <FontAwesomeIcon icon={faCalendar} className='thebookIcon' /> 
        <div>
          <h6>Trip Date</h6>
          <p>{theselectedDay} at {theselectedTime}</p>
        </div>
      </div>

      <div className='bookingIcons'>
        <FontAwesomeIcon icon={faUsers} className='thebookIcon' />
        <div>
          <h6>Total Passangers</h6>
          <p>{thenoOfPassangers}</p>
        </div>
      </div>

      <div className='bookingIcons'>
        <FontAwesomeIcon icon={faWallet} className='thebookIcon' />
        <div>
          <h6>Amount Paid</h6>
          <p>KES. {formatPrice(theamountPaid)}</p>
        </div>
      </div>
      <hr/>
      <p> <FontAwesomeIcon icon={faCamera} /> Free photographer included</p>

     
      <div className='bookButtonCont'>
          <div>
            <p>{thestatus}</p>
          </div>
          <button onClick={() => viewElectronicTicket()}>View Electronic Ticket</button>
        </div>

    </div>
  )
}

export default ViewFlightBookingsComp