import React, { useRef, useState } from 'react'
import LeftBar from '../Components/LeftBar'
import NavBar from '../Components/NavBar'
import './Dash.css'
import RightBar from '../Components/RightBar'
import HomeComp from './HomeComp'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch} from '@fortawesome/free-solid-svg-icons'
import { faAdd} from '@fortawesome/free-solid-svg-icons'
import HeliCharters from './HeliCharters'
import SmallAircraftCharters from './SmallAircraftCharters'
import PrivateJetCharters from './PrivateJetCharters'

import heliBlack from '../Images/myhelliblack.png'
import heliWhite from '../Images/myhelliwhite.png'

import smallWhite from '../Images/smallwhite.png'
import smallBlack from '../Images/smallblack.png'

import planeBlack from '../Images/planeblack.png'
import planeWhite from '../Images/planewhite.png'

function Home() {

    const [showProgress, setShowProgress] = useState(true);
    const [showSignInbtn, setShowSignInbtn] = useState(false);
    const [showRegnbtn, setShowRegnbtn] = useState(false);
  
    const [showWineBtn, setshowWineBtn] = useState(true);
    const [showFoodBtn, setshowFoodBtn] = useState(true);
    const [showMixBtn, setshowMixBtn] = useState(true)
  
    const [showWineBtn2, setshowWineBtn2] = useState(false);
    const [showFoodBtn2, setshowFoodBtn2] = useState(false);
    const [showMixBtn2, setshowMixBtn2] = useState(false)
  
  
    function showWine(){
  
      setshowWineBtn(true)
      setshowWineBtn2(false)
      setshowMixBtn(true)
      setshowMixBtn2(false)

      setshowFoodBtn2(false)
      setshowFoodBtn(true)

      setShowProgress(true);
      setShowSignInbtn(false)
      setShowRegnbtn(false)

    }
  
    function showFood(){

      setshowFoodBtn2(true)
      setshowFoodBtn(false)

      setshowWineBtn(false)
      setshowWineBtn2(true)
      setshowMixBtn(true)
      setshowMixBtn2(false)

      setShowProgress(false);
      setShowSignInbtn(true)
      setShowRegnbtn(false)

  
    }
  
    function showMix(){

      setshowMixBtn(false)
      setshowMixBtn2(true)
      setshowWineBtn(false)
      setshowWineBtn2(true)

      setshowFoodBtn2(false)
      setshowFoodBtn(true)

      setShowProgress(false);
      setShowSignInbtn(false)
      setShowRegnbtn(true)
    }
    
  return (
    <div className='contentMain'> 
      <LeftBar/>
      <div className='theRightOne HomeCont'> 
      <h1 className='heroText'>Choose Your Ideal Air Trip</h1>

      <div className='mymenu'>

        {showWineBtn &&  <button className='wineBtn' onClick={showWine}>
          <img src={heliWhite} className='aircraftImg' alt="" />
          Helicopter Charters
        </button>}

        {showFoodBtn && <button className='foodBtn' onClick={showFood}>
            <img src={planeBlack} className='aircraftImg' alt="" />
          Small Aircraft Charters
        </button>}

        {showMixBtn && <button className='foodBtn' onClick={showMix}>
        <img src={smallBlack} className='aircraftImg' alt="" />
          Private Jet Charters
          </button>}
      
        {showWineBtn2 &&  <button className='wineBtn2' onClick={showWine}>
        <img src={heliBlack} className='aircraftImg' alt="" />
        Helicopter Charters</button>}

        {showFoodBtn2 && <button className='foodBtn2' onClick={showFood}>
          <img src={planeWhite} className='aircraftImg' alt="" />
          Small Aircraft Charters
        </button>}

        {showMixBtn2 && <button className='foodBtn2' onClick={showMix}>
          <img src={smallWhite} className='aircraftImg' alt="" />
          Private Jet Charters
        </button>}
      </div>


      {showSignInbtn && 
         
         <SmallAircraftCharters/>
      }   
      
      {showProgress && 
        <HeliCharters/> 
      }
      {showRegnbtn &&
        <PrivateJetCharters/>
      }

     </div>
     <RightBar/>
    </div>
  )
}

export default Home