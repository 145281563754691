import React, { useRef, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Table from 'react-bootstrap/Table';
import { getFirestore } from "@firebase/firestore";
import { app } from '../firebase.js';
import { collection, doc, orderBy, setDoc,query, getDocs, deleteDoc, where, updateDoc} from "@firebase/firestore"; 
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import swal from 'sweetalert';
import Spinner from 'react-bootstrap/Spinner';

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {useNavigate } from "react-router-dom";
import helliimage from '../Images/heliimageone.jpeg'


function TripsComp() {

  const [studentsList, setStudentList] = useState([]);
  const db = getFirestore(app);
  const auth = getAuth();
  const storage = getStorage();
  let navigate = useNavigate();
  



    //fetch all menu items
    useEffect(() => {     
      const fetchData = async () => {
        const user = auth.currentUser;
        if (!user) {
          console.log('No user is logged in');
          return;
        }
        
        const userId = user.uid;
        const q = query(
          collection(db, 'Bookings'), 
          where('userId', '==', userId),
          orderBy('timestamp', 'desc')
        );
  
        let studentsItem = [];
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          studentsItem.push({ id: doc.id, ...doc.data() });
        });
        setStudentList(studentsItem);   
      };
  
      fetchData();
    }, [db, auth]);
    //end fetching menu items

    function formatDate(dateString) {
      const date = new Date(dateString);
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      const formattedDate = date.toLocaleDateString('en-US', options);
    
      return formattedDate;
    }

    function formatDateTime(dateTimeString) {
      const date = new Date(dateTimeString);
      const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true // Use 12-hour format
      };
      const formattedDateTime = date.toLocaleDateString('en-US', options);
      const timeString = date.toLocaleTimeString('en-US', options);
    
      return `${formattedDateTime}`;
    }

    function viewCase(documentId, bookingType){

      if(bookingType == "Experience"){
        navigate('/view-bookings', { state: { myData: documentId } });
      }else{
        navigate('/view-flight-bookings', { state: { myData: documentId } });
      }
    }

  return (
    <div className='mainContentDiv'>
      <h1 className='heroText'>Bookings</h1>


      {studentsList.map((mystudent) => (
      <div className='tripCont' key={Math.random()} onClick={() => viewCase(mystudent.documentId, mystudent.bookingType)}>
          <div className='tripImgCont'>
            <img src={mystudent.expImage} alt="" />
          </div> 
          <div className='tripDataCont'>
            <p className='tripType'>{mystudent.bookingType}</p>
            <h5>{mystudent.sendDesc}</h5>
            <p className='tripDate'>{mystudent.timestamp.toDate().toLocaleString()}</p>
            <p className='pendingTrip'>{mystudent.status}</p>
          </div> 
      </div>
       ))}

    </div>
  )
}

export default TripsComp