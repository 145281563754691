import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate, useLocation } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import { app } from '../firebase.js';
import { getFirestore, collection, query, getDocs, doc, where, addDoc, setDoc, Timestamp } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import axios from 'axios';
import swal from 'sweetalert';

function ConfirmBookingComp() {
  const navigate = useNavigate();
  const location = useLocation();
  const db = getFirestore(app);
  const auth = getAuth();
  
  const [theBookingFee, setBookingFee] = useState(0);
  const [numPassengers, setNumPassengers] = useState(1);
  const [totalAmount, setTotalAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState('Sunday 4th 2024');
  const [selectedTime, setSelectedTime] = useState('8:45 AM');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState(''); // State to store the error message
  const [myData, setMyData] = useState(null); // State to store the received data
  const [theexpImage, setexpImage] = useState();
  const [thedesc, setdesc] = useState();

  // Receive data from the previous page
  useEffect(() => {
    const data = location.state?.myData;
    setMyData(data);
  }, [location.state]);

  useEffect(() => {
    // Fetch data from Firebase when myData changes
    const fetchData = async () => {
      if (myData) {
        try {
          const querySnapshot = await getDocs(query(collection(db, 'experiences'), where('docID', '==', myData)));
          querySnapshot.forEach(async (doc) => {
            const pricePerPerson = doc.data().pricePerPerson;
            const price = parseInt("1");
            let expImage = doc.data().expImage;
            const desc = doc.data().desc

            setBookingFee(price);
            setTotalAmount(price * numPassengers); // Initialize total amount
            setexpImage(expImage);
            setdesc(desc)
          });
        } catch (error) {
          console.error('Error fetching data from Firebase:', error);
        }
      }
    };

    // Initial fetch
    fetchData();
  }, [myData, db]);

  useEffect(() => {
    // Update total amount when the number of passengers changes
    setTotalAmount(theBookingFee * (numPassengers || 1));
  }, [numPassengers, theBookingFee]);

  const handlePassengerChange = (e) => {
    const value = e.target.value;
    setNumPassengers(value === '' ? '' : parseInt(value, 10)); // Allow empty input
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const handleTimeChange = (e) => {
    setSelectedTime(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhoneNumber(e.target.value); // Allow user to type any format
  };

  const formatPhoneNumber = (number) => {
    // Strip non-numeric characters
    let cleaned = number.replace(/[^0-9]/g, '');

    // Handle various cases
    if (cleaned.startsWith('0')) {
      cleaned = '254' + cleaned.slice(1); // Replace leading 0 with 254
    } else if (cleaned.startsWith('254')) {
      // No change needed
    } else if (cleaned.startsWith('7')) {
      cleaned = '254' + cleaned; // Add 254 if starts with 7
    } else if (cleaned.startsWith('+254')) {
      cleaned = cleaned.slice(1); // Remove the + sign
    } else if (cleaned.startsWith('1')) {
      cleaned = '254' + cleaned; // For landlines or similar cases starting with 1
    }

    // Ensure the number has 12 digits
    if (cleaned.length === 12 && cleaned.startsWith('254')) {
      return cleaned;
    } else {
      setError('Please enter a valid phone number in the format 254XXXXXXXXX');
      return null;
    }
  };

  const formatPrice = (price) => {
    const num = Number(price);
    return isNaN(num) ? 'N/A' : num.toLocaleString();
  };

  function generateTransactionRef(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }

    return result;
  }

  const sendStkPush = async (phone, amount) => {
    try {
      const response = await axios.post('https://us-central1-pepea-a9eaa.cloudfunctions.net/api/stkpush', {
        phone,
        amount
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const data = response.data;
      console.log('STK Push Response:', data);

      if (data.ResponseCode === '0') {
        // STK Push sent successfully
        swal({
          title: 'STK Push Sent',
          text: data.CustomerMessage,
          icon: 'success',
          buttons: {
            confirm: {
              text: 'I have paid',
              value: 'confirm',
              className: 'confirm-button'
            },
            cancel: 'I haven\'t paid'
          },
          content: {
            element: 'div',
            attributes: {
              innerHTML: '<style>.confirm-button { position: relative; }</style>'
            }
          }
        }).then((value) => {
          if (value === 'confirm') {
            setIsLoading(true); // Show loading spinner on the "I have paid" button

            verifyPayment(data.CheckoutRequestID).then(() => {
              setIsLoading(false); // Hide loading spinner after payment confirmation
            });
          } else {
            swal('Please complete the payment and try again.');
          }
        });
      } else {
        swal('Failed to send STK Push Request');
      }
    } catch (error) {
      console.error('Error sending STK Push request:', error);
      swal('Error sending STK Push Request. Please try again.');
    }
  };

  const verifyPayment = async (checkoutRequestId) => {
    try {
      const response = await axios.post(
        'https://us-central1-pepea-a9eaa.cloudfunctions.net/api/query',
        {
          queryCode: checkoutRequestId  // Ensure correct field name here
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
  
      const data = response.data;
      console.log('Payment Verification Response:', data);
  
      if (data.ResultCode === '0') {

        const transactionRef = generateTransactionRef(7);
        const currentDate = Timestamp.now();
        const formattedPhone = formatPhoneNumber(phoneNumber);

        const newDataRef = doc(collection(db, "Bookings"));
        setDoc(newDataRef, {
        CheckoutRequestID: data.CheckoutRequestID,
        MerchantRequestID: data.MerchantRequestID,
        amountPaid: totalAmount,
        bookingType: "Experience",
        expImage: theexpImage,
        listingAmount: theBookingFee,
        noOfPassangers: numPassengers.toString(), // Ensure it's a string as per your requirements
        phonenumber: formattedPhone,
        selectedDay: selectedDate,
        selectedTime: selectedTime,
        sendDesc: thedesc, // Replace with actual description
        status: 'Pending Trip',
        thedocIDbooked: myData,
        timestamp: currentDate.toDate(), // Convert to JavaScript Date object
        transactionRef,
        userId: auth.currentUser.uid,
        documentId:newDataRef.id
      }).then(()=>{
          // Display success message to the user
          const smsMessage = `Your booking for ${thedesc} is confirmed. Amount: KES ${formatPrice(theBookingFee)}. Ref: ${transactionRef}. Download the PDF Electronic Ticket and present before boarding.`;
          const smsSent =  sendSms(smsMessage, formattedPhone, transactionRef);

          if (smsSent) {
            swal('Payment Verified', 'Booking completed successfully. Download the PDF Electronic Ticket and present it before boarding.', 'success').then(() => {
              navigate('/trips');
            });
          } else {
            swal('Payment Verified', 'Booking completed. Download the PDF Electronic Ticket and present it before boarding', 'success').then(() => {
              navigate('/trips');
            });
          }
      });

      } else {
        swal('Payment Not Verified', 'Your payment could not be verified.', 'error');
      }

    } catch (error) {
      console.error('Error verifying payment:', error);
      swal('Error verifying payment. Please try again.');
    }
  };
  
  const sendSms = async (message, phoneNumber, transRef) => {
    const url = 'https://us-central1-pepea-a9eaa.cloudfunctions.net/hellosms/sendsms';
    const requestBody = {
      message,
      phoneNumber,
      transRef,
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        const responseData = await response.json();
        console.log(responseData);
        return true;
      } else {
        console.error(`HTTP error! Status: ${response.status}`);
        return false;
      }
    } catch (error) {
      console.error('Error:', error);
      return false;
    }
  };

  const makePayment = () => {
    if (!numPassengers || numPassengers < 1) {
      setError('Please enter a valid number of passengers.');
      return;
    }

    const formattedPhone = formatPhoneNumber(phoneNumber);
    if (!formattedPhone) {
      return; // Stop if phone number is invalid
    }

    setError(''); // Clear error if phone number is valid
    setIsLoading(true); // Show loading spinner

    // Send STK Push with formatted phone number and total amount
    sendStkPush(formattedPhone, totalAmount).finally(() => {
      setIsLoading(false); // Hide loading spinner after request completion
    });
  };

  return (
    <div>
      <Form.Group className="mb-3" controlId="date">
        <Form.Label>Select Date</Form.Label>
        <Form.Select aria-label="Default select example" onChange={handleDateChange}>
          <option value="Sunday 4th 2024">Sunday 4th 2024</option>
          <option value="Monday 5th 2024">Monday 5th 2024</option>
          <option value="Tuesday 6th 2024">Tuesday 6th 2024</option>
        </Form.Select>
      </Form.Group>

      <Form.Group className="mb-3" controlId="time">
        <Form.Label>Select Time</Form.Label>
        <Form.Select aria-label="Default select example" onChange={handleTimeChange}>
          <option value="8:45 AM">8:45 AM</option>
          <option value="10:00 AM">10:00 AM</option>
          <option value="6:00 PM">6:00 PM</option>
        </Form.Select>
      </Form.Group>

      <p>Selected Date and Time</p>
      <Alert className="customAlert">
        <p>Time: {selectedTime}</p>
        <p>Date: {selectedDate}</p>
      </Alert>

      {error && <Alert variant="danger">{error}</Alert>}

      <Form.Group className="mb-3" controlId="passengers">
        <Form.Label>Number of Passengers</Form.Label>
        <Form.Control
          type="number"
          name="passengers"
          value={numPassengers}
          onChange={handlePassengerChange}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="phone">
        <Form.Label>Phone number to receive MPESA Payment prompt</Form.Label>
        <Form.Control
          type="text"
          name="phone"
          value={phoneNumber}
          onChange={handlePhoneChange}
        />
      </Form.Group>

      <br/>
      <div className='bookButtonCont'>
        <div>
          <p>Total Amount</p>
          <h6>KES. {formatPrice(totalAmount)}</h6>
        </div>
        <Button onClick={makePayment} disabled={isLoading}>
          {isLoading ? (
            <>
              <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
              <span> Processing...</span>
            </>
          ) : (
            "Make Payment"
          )}
        </Button>
      </div>

    </div>
  )
}

export default ConfirmBookingComp;
