import React, { useRef, useState, useEffect } from 'react'
import './Leftbar.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser} from '@fortawesome/free-solid-svg-icons'
import { faBookmark} from '@fortawesome/free-solid-svg-icons'
import { faLocationDot} from '@fortawesome/free-solid-svg-icons'
import { faPlane} from '@fortawesome/free-solid-svg-icons'
import { faHelicopter} from '@fortawesome/free-solid-svg-icons'
import { faVideo} from '@fortawesome/free-solid-svg-icons'
import { faStarOfLife} from '@fortawesome/free-solid-svg-icons'

//routers
import {useNavigate } from "react-router-dom";
import {Link } from "react-router-dom";
import {NavLink } from "react-router-dom";

import logo from '../Images/logowhite.svg'
import androidApp from '../Images/androiddownload.png'
import appleApp from '../Images/iphone.jpeg'

import {app} from '../firebase.js'
import { getFirestore } from "@firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, doc, setDoc,query, getDocs, deleteDoc, where} from "@firebase/firestore"; 


function LeftBar() {

    let navigate = useNavigate();
    //navigate("/") 

    const db = getFirestore(app);
    const [loggedInUser, setloggedInUser] = useState("");
    const [userProfPic, setuserProfPic] = useState("");
    const [userPhone, setUserPhone] = useState("");
  
      const auth = getAuth();
      onAuthStateChanged(auth, (user) => {
        if (user) {
          const uid = user.uid;
          // ...
  
          const j = query(
            collection(db, "users"),
            where("userId", "==", uid)
          );
          getDocs(j).then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              let fullname = doc.data().fullname;
              let profileImage = doc.data().profileImage;
              let phone = doc.data().phone;
              setloggedInUser(fullname)
              setuserProfPic(profileImage)
              setUserPhone(phone)
            });
        
          });
  
  
        } else {
          // User is signed out
          navigate("/")  
        }
      });
  
   

  return (
    <div className='leftbar'>

    <div className='menuContainer'>

        <div className='logocontainer'>
            <div className='logoMkebe'>
                  <img src={logo}  alt="" />
            </div>
            <div className='profPicLeft'>
                <img src={userProfPic} alt="" />

            </div>
        </div>

        <div className='leftNameCont'>
            <h6>{loggedInUser}</h6>
            <p>@{userPhone}</p>
        </div>

        
        <div className='leftLinkCont d-flex align-items-center' >
            <NavLink to="/home" className='leftNavLinks ' >
                <FontAwesomeIcon icon={faVideo} className="laftNavIcon"/>
                Home
            </NavLink>
        </div>


            <div className='leftLinkCont d-flex align-items-center'>
                <NavLink to="/experiences" className='leftNavLinks '>
                    <FontAwesomeIcon icon={faHelicopter} className="laftNavIcon"/>
                    Experiences
                </NavLink>
            </div>

            <div className='leftLinkCont d-flex align-items-center'>
                <NavLink to="/express" className='leftNavLinks '>
                    <FontAwesomeIcon icon={faStarOfLife} className="laftNavIcon"/>
                    Express
                </NavLink>
            </div>

            <div className='leftLinkCont d-flex align-items-center'>
                <NavLink to="/trips" className='leftNavLinks '>
                    <FontAwesomeIcon icon={faLocationDot} className="laftNavIcon"/>
                    Trips
                </NavLink>
            </div>

            <div className='leftLinkCont d-flex align-items-center' >
                <NavLink to="/explore" className='leftNavLinks ' >
                    <FontAwesomeIcon icon={faVideo} className="laftNavIcon"/>
                    Explore
                </NavLink>
            </div>

            <div className='leftLinkCont d-flex align-items-center'>
                <NavLink to="/favourites" className='leftNavLinks '>
                    <FontAwesomeIcon icon={faBookmark} className="laftNavIcon"/>
                    Favourites
                </NavLink>
            </div>

            <div className='leftLinkCont d-flex align-items-center'>
                <NavLink to="/profile" className='leftNavLinks '>
                    <FontAwesomeIcon icon={faUser} className="laftNavIcon"/>
                   Profile
                </NavLink>
            </div>


            <div className='downloadCont'>
                <a href="https://play.google.com/store/apps/details?id=com.pepea.app"><img src={androidApp} alt="" /></a>
                <a href="https://www.apple.com/app-store/"><img src={appleApp} alt="" /></a>
            </div>



    </div>





</div>
  )
}

export default LeftBar