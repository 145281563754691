import React, { useRef, useState } from 'react';
import './Auth.css';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';

// Routers
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';

import { app } from '../firebase.js';
import { getFirestore } from '@firebase/firestore';

import logo from '../Images/logoblack.svg';

import kenyaImg from '../Images/kenyaflag.png'
import ugandaFlag from '../Images/ugandaflag.png'  
import InputGroup from 'react-bootstrap/InputGroup';  

import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signOut
} from 'firebase/auth';

import { collection, doc, setDoc } from '@firebase/firestore';

function Register() {
  let navigate = useNavigate();
  const emailRef = useRef();
  const passwordRef = useRef();
  const nameRef = useRef();
  const phoneRef = useRef();

  const [showProgress, setShowProgress] = useState(false);
  const [showSignInbtn, setShowSignInbtn] = useState(true);
  const [selectedOption, setSelectedOption] = useState('');

  async function loginUser() {
    const email = emailRef.current.value;
    const password = passwordRef.current.value;
    const username = nameRef.current.value;
    const phone = phoneRef.current.value;
    const country = selectedOption.current.value;
    const timestamp = new Date();


    setShowProgress(true);
    setShowSignInbtn(false);

    const auth = getAuth();
    const db = getFirestore(app);

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);

      // Send email verification
      await sendEmailVerification(auth.currentUser);

      // Update user data in the database
      const userID = userCredential.user.uid;
      const newUserRef = doc(db, 'users', userID);
      await setDoc(newUserRef, {
        email: email,
        phone: phone,
        signupdate: timestamp,
        userId: userID,
        usertype: 'enduser',
        country: country,
        registeredVia: 'Web',
        isuserVerified: 'no',
        fullname: username,
      });

        // Example usage:
        const message = "Hello "+username+ "! Welcome to Fly Pepea. We're thrilled to have you on board! Get ready for an exclusive and luxurious travel experience tailored just for you. If you have any questions or need assistance, feel free to reach out. Happy travels!";
        const phoneNumber = phone; 
        const transRef = "TransactionReference"; 
        
        sendSms(message, phoneNumber, transRef);

        // Log out the user
        await signOut(auth);

        setShowProgress(false);
        setShowSignInbtn(true);

      // Redirect to the dashboard after successful signup
     
      swal({
        title: "Registration Successful!",
        text: "Please check your email for verification link",
        icon: "success",
        button: "Okay",
      }).then((value) => {
        navigate('/');
      });

    } catch (error) {
      setShowProgress(false);
      setShowSignInbtn(true);

      const errorCode = error.code;
      const errorMessage = error.message;
      swal('Login Error!', '' + errorMessage + '', 'error');
    }
  }
  


  async function sendSms(message, phoneNumber, transRef) {
    const url = 'https://us-central1-pepea-a9eaa.cloudfunctions.net/hellosms/sendsms';
    const requestBody = {
      message,
      phoneNumber,
      transRef,
    };
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
  
      if (response.ok) {
        const responseData = await response.json();
        // Handle the response as needed
        console.log(responseData);
        return true;
      } else {
        console.error(`HTTP error! Status: ${response.status}`);
        return false;
      }
    } catch (error) {
      console.error('Error:', error);
      return false;
    }
  }
  




  const [countryLogo, setcountryLogo] = useState(kenyaImg);
  const [userCode, setUserCode] = useState("+254");
  const [show, setShow] = useState(false);

  const handleSelectChange = (event) => {
      const selectedValue = event.target.value;
      setSelectedOption(selectedValue);

      if(selectedValue == "Kenya"){

          setUserCode("+254");
          setcountryLogo(kenyaImg)

      }else if (selectedValue == "Uganda"){
          setUserCode("+256");
          setcountryLogo(ugandaFlag)
      }
      
  };
  


  
  return (
    <div className='mainAuth'>
      <div className='AuthRight'>
        <img src={logo} alt='' className='logoAuth' />
   

        <Form.Group className='mb-3' controlId='formBasicEmail'>
          <Form.Label>Full Name</Form.Label>
          <Form.Control type='text' placeholder='Your full name' ref={nameRef} />
        </Form.Group>

        <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">
                <img src={countryLogo}  className='countryIcon' alt="" />
            
            </InputGroup.Text>
              <Form.Select aria-label="Default select example" value={selectedOption} onChange={handleSelectChange}>
                <option value="Kenya">Kenya</option>
                <option value="Uganda">Uganda</option>
             </Form.Select>
             </InputGroup>

             <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">{userCode}</InputGroup.Text>
                <Form.Control
                placeholder="phone number"
                aria-label="Username"
                aria-describedby="basic-addon1"
                type="number" 
                className='myForms'
                ref={phoneRef} 
                />
        </InputGroup>

        <Form.Group className='mb-3' controlId='formBasicEmail'>
          <Form.Label>Email address</Form.Label>
          <Form.Control type='email' placeholder='Enter email' ref={emailRef} />
        </Form.Group>

        <Form.Group className='mb-3' controlId='formBasicPassword'>
          <Form.Label>Password</Form.Label>
          <Form.Control type='password' placeholder='Password' ref={passwordRef} />
        </Form.Group>

        {showSignInbtn && (
          <Button variant='primary' type='submit' className='btnLogin' onClick={loginUser}>
            Sign up
          </Button>
        )}

        {showProgress && (
          <Button variant='dark' disabled className='btnLogin'>
            <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
            <span> Signing up...</span>
          </Button>
        )}

        <div className='signuptextCont'>
            <Link to='/' className='signuptext'>Login to your Account </Link> <h6>|</h6>
            <Link to='/reset-password' className='signuptext'> Reset Password</Link>
        </div>
 
      </div>

      <div className='AuthLeft'>
        <div className='authleftCont'>
          <video
            autoPlay
            muted
            loop
            src='https://res.cloudinary.com/ubunifu/video/upload/v1704724179/pexels-ana-benet-8242842_1080p_mlammz.mp4'
          ></video>
          <div className='authLeftOpacity'></div>
        </div>
      </div>
    </div>
  );
}

export default Register;
