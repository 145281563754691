import React, { useRef, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Table from 'react-bootstrap/Table';
import { getFirestore } from "@firebase/firestore";
import { app } from '../firebase.js';
import { collection, doc, orderBy, setDoc,query, getDocs, deleteDoc, where, updateDoc} from "@firebase/firestore"; 
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import swal from 'sweetalert';
import Spinner from 'react-bootstrap/Spinner';

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {useNavigate } from "react-router-dom";


function ExperienceComp() {

    const [studentsList, setStudentList] = useState([]);
    const db = getFirestore(app);
    const auth = getAuth();
    const storage = getStorage();
    let navigate = useNavigate();
    const [loading, setLoading] = useState(true);
  
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const q = query(
            collection(db, 'experiences'),
            where('Availability', '==', 'available'),
            orderBy('timestamp', 'desc')
          );
          const querySnapshot = await getDocs(q);
          let studentsItem = [];
          querySnapshot.forEach((doc) => {
            studentsItem.push({ id: doc.id, ...doc.data() });
          });
          setStudentList(studentsItem);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoading(false);
        }
      };
  
      fetchData();
    }, [db]);


  function viewCase(documentId){
    navigate('/experience-bookings', { state: { myData: documentId } });
  }

  const formatPrice = (price) => {
    // Convert price to a number if it’s not already
    const num = Number(price);
    // Check if the conversion resulted in NaN
    if (isNaN(num)) {
      return 'N/A'; // Fallback for invalid numbers
    }
    return num.toLocaleString();
  };

  if (loading) {
    return (
      <div className="spinnerContainer">
        <Spinner animation="border" />
      </div>
    );
  }


  if (!studentsList.length) {
    return <div>No available experiences found.</div>;
  }

  return (
    <div className='mainContentDiv'>

        <h1 className='heroText'>Experiences</h1>

        {studentsList.map((mystudent) => (
        <div className='experienceDiv' key={Math.random()} onClick={() => viewCase(mystudent.docID)}>
          <div className='experienceDivImageCont'>
            <img src={mystudent.expImage} alt="" />
          </div>
          <p>{mystudent.durationDesc}</p>
          <h6>{mystudent.desc}</h6> 
          <p>From KES. {formatPrice(mystudent.pricePerPerson)} per person</p>
        </div>
        ))}


    </div>
  )
}

export default ExperienceComp