import React, { useRef, useState, useEffect } from 'react';
import defCover from '../Images/defcover.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlay, faCamera, faLocationDot, faLocationCrosshairs, faFingerprint } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import { app } from '../firebase.js';
import { getFirestore, collection, query, getDocs, where } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import swal from 'sweetalert';
import Table from 'react-bootstrap/Table';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import QRCode from 'qrcode.react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

function ViewFlightTicketComp() {

    const navigate = useNavigate();
    const location = useLocation();
    const db = getFirestore(app);
    const auth = getAuth();
    const [theexpImage, setexpImage] = useState();
    const [thedesc, setdesc] = useState();
    const [theselectedDay, setselectedDay] = useState();
    const [theselectedTime, setselectedTime] = useState();
    const [thenoOfPassangers, setnoOfPassangers] = useState();
    const [thetransactionRef, settransactionRef] = useState();
    const [thestatus, setstatus] = useState();
    const [thedurationDesc, setdurationDesc] = useState();
    const [theoperatedBy, setoperatedBy] = useState();
    const [theboardingArea, setboardingArea] = useState();
    const [thedropOffArea, setdropOffArea] = useState();
    const [thebookingfee, setbookingfee] = useState();
    const [theflight, setflight] = useState();
    const [thefullname, setfullname] = useState();
    const [thenationalID, setnationalID] = useState();
    const [thewhereto, setwhereto] = useState();
    const [thenumberOfHours, setnumberOfHours] = useState()

    const [myData, setMyData] = useState(null); // State to store the received data

    const ticketRef = useRef(); // Reference to the ticket container
  
    // Receive data from the previous page
    useEffect(() => {
      const data = location.state?.myData;
      setMyData(data);
    }, [location.state]);
  
    const qrData = myData; // Replace with your QR code data
    useEffect(() => {
        const fetchData = async () => {
          if (myData) {
            try {
              const querySnapshot = await getDocs(query(collection(db, 'Bookings'), where('documentId', '==', myData)));
              querySnapshot.forEach(async (doc) => {
                let expImage = doc.data().expImage;
                const desc = doc.data().sendDesc;
                const selectedDay = doc.data().selectedDay;
                const selectedTime = doc.data().selectedTime;
                const noOfPassangers = doc.data().noOfPassangers;
                const thedocIDbooked = doc.data().thedocIDbooked;
                const status = doc.data().status;
                const transactionRef = doc.data().transactionRef;
                const userId = doc.data().userId;
                const whereto = doc.data().whereto;
                const numberOfHours = doc.data().numberOfHours;
    
                setexpImage(expImage);
                setdesc(desc);
                setselectedDay(selectedDay);
                setselectedTime(selectedTime);
                setnoOfPassangers(noOfPassangers);
                setstatus(status);
                settransactionRef(transactionRef);
                setwhereto(whereto)
                setnumberOfHours(numberOfHours)
    
                try {
                  const querySnapshot = await getDocs(query(collection(db, 'flights'), where('docID', '==', thedocIDbooked)));
                  querySnapshot.forEach(async (doc) => {
                    const durationDesc = doc.data().durationDesc;
                    const boardingArea = doc.data().TakeOffArea                    ;
                    const dropOffArea = doc.data().dropOffArea;
                    const pricePerPerson = doc.data().pricePerPerson;
                    const flight = doc.data().flight;
                    const operatedBy = doc.data().operatedBy;
    
                    setdurationDesc(durationDesc);
                    setboardingArea(boardingArea);
                    setdropOffArea(dropOffArea);
                    setflight(flight);
                    setoperatedBy(operatedBy);
    
                    const theprice = parseInt(pricePerPerson);
                    setbookingfee(theprice);
                  });
                } catch (error) {
                  console.error('Error fetching data from Firebase:', error);
                }
    
                try {
                  const querySnapshot = await getDocs(query(collection(db, 'users'), where('userId', '==', userId)));
                  querySnapshot.forEach(async (doc) => {
                    const fullname = doc.data().fullname;
                    const nationalID = doc.data().nationalID;
    
                    setfullname(fullname);
                    setnationalID(nationalID);
                  });
                } catch (error) {
                  console.error('Error fetching data from Firebase:', error);
                }
              });
            } catch (error) {
              console.error('Error fetching data from Firebase:', error);
            }
          }
        };
    
        // Initial fetch
        fetchData();
      }, [myData, db]);
    
      // Function to handle PDF download
       // Function to handle PDF download
       const handleDownload = async () => {
        const element = ticketRef.current;
        const canvas = await html2canvas(element);
        const imgData = canvas.toDataURL('image/png');
    
        // Create a new jsPDF instance in A4 size
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        
        // Calculate the image dimensions
        const imgProps = pdf.getImageProperties(imgData);
        const imgWidth = pdfWidth - 20; // Subtracting margins
        const imgHeight = (imgProps.height * imgWidth) / imgProps.width;
    
        // Ensure the image fits within the page height, scaling if necessary
        let finalImgHeight = imgHeight;
        let topMargin = 10;
        if (imgHeight > pdfHeight - 20) {
          finalImgHeight = pdfHeight - 20;
          topMargin = (pdfHeight - finalImgHeight) / 2;
        }
    
        pdf.addImage(imgData, 'PNG', 10, topMargin, imgWidth, finalImgHeight);
        pdf.save(`ticket_${thetransactionRef}.pdf`);
      };
    
      return (
        <div className='viewTicketContainer'>
          <div className='viewTicketContent' ref={ticketRef}>
            <h2>Fly Pepea</h2>
            <div className='ticketTopCont'>
              <div className='ticketTop'>
                <p><span className='boldSpanTicket'>Phone:</span> 0714870528</p>
                <p><span className='boldSpanTicket'>Website:</span>www.flypepea.com</p>
                <p><span className='boldSpanTicket'>Website:</span>info@flypepea.com</p>
              </div>
              <div className='qrCodeContainer'>
                <QRCode value={qrData} size={110} />
              </div>
            </div>
            <h4 className='ticketH4'>Electronic Ticket</h4>
            <p className='ticketP'>Show this at check in. No other ticket will be issued</p>
            <p className='ticketP'>BOOKING NUMBER: {thetransactionRef}</p>
            <hr className='tickethr1' />
            <div className='ticketTopCont2'>
              <p className='itinearyP'>ITINERARY</p>
              <p className='ticketP'>Operated by {theoperatedBy}</p>
            </div>
            <p className='ticketP'>Passenger Name: {thefullname}</p>
            <p className='ticketP'>Passport Number: {thenationalID}</p>
            <p className='ticketP'>Number of Passengers: {thenoOfPassangers}</p>
            <p className='ticketP'>Trip: {thedesc}</p>
            <div className='ticketTopCont3'>
              <div>
                <p><span className='boldSpanTicket'>From</span></p>
                <p>{theboardingArea}</p>
              </div>
              <div>
                <p><span className='boldSpanTicket'>Destination</span></p>
                <p>{thewhereto}</p>
              </div>
              <div>
                <p><span className='boldSpanTicket'>When</span></p>
                <p>{theselectedDay}</p>
              </div>
              <div>
                <p><span className='boldSpanTicket'>Time</span></p>
                <p>{theselectedTime}</p>
              </div>
              <div>
                <p><span className='boldSpanTicket'>Flight</span></p>
                <p>{theflight}</p>
              </div>
            </div>
            <p className='ticketP'>CHECKIN INFO: Check-in by 1 hour before time</p>
            <p className='ticketP'>Flight Duration: {thenumberOfHours} Hours</p>
            <hr className='tickethr1' />
            <div className='ticketTopCont2'>
              <p className='itinearyP'>TERMS AND CONDITIONS</p>
            </div>
            <p class='ticketP2'>
        1. The Carrier shall mean operator whose aircraft has been chartered to perform this flight whether the actual name is by abbreviation or in full, as indicated above.
    </p>
    <p class='ticketP2'>
        2. If the carriage under and in terms of this passenger ticket is 'international carriage' as defined by the Convention for the Unification of Certain Rules Relating to International Carriage by Air signed at Warsaw, 12th October, 1929, or any amendment thereof (the 'Convention'), then such carriage will be subject to all rules and limitations relating to liability established by the Convention.
    </p>
    <p class='ticketP2'>
        3. In respect of all carriage not subject to and/or governed by the Convention, all carriage and all services and operations rendered and/or undertaken by the Carrier shall be subject to the terms and conditions set out below, in accordance with the Aircraft Charter Agreement with the Charterer and related regulations which are made part hereof (and are available on application at the offices of the Carrier).
    </p>
    <p class='ticketP2'>
        4. The Carrier shall not board any passenger without the express approval of the Charterer.
    </p>
    <p class='ticketP2'>
        5. The Carrier shall not accept any unaccompanied baggage or cargo without the express approval of the Charterer. The Carrier shall accept full responsibility for damage to baggage or cargo taken into the Carrier's custody by his agent, in accordance with the Aircraft Charter Agreement with the Charterer.
    </p>
    <p class='ticketP2'>
        6. The Charterer bears no liability whatsoever to the passenger in the event of death or injury, or damage to baggage or cargo, sustained in connection with all carriage and all services and operations rendered and/or undertaken by the Carrier under this contract.
    </p>
    <p class='ticketP2'>
        7. Should a flight be delayed or made impossible by reason or any cause whatsoever, no action or claim shall arise against the Carrier or the Charterer whether such delay or impossibility was occasioned by the fault or negligence of the Carrier or the Charterer and/or any of its officers, employees or agents.
    </p>
    <p class='ticketP2'>
        8. No agent, servant or representative of the Carrier has authority to alter, modify or waive any provision of this contract.
    </p>
    <p class='ticketP2'>
        9. Any action taken against the Carrier shall be brought only in a court of competent jurisdiction in the country of the Carrier's origin.
    </p>
    <p class='ticketP2'>
        10. Check-in is 1 hour prior to indicated departure time.
    </p>
    
          </div>
          <div className='bookButtonCont'>
            <div>
              <p>{thestatus}</p>
            </div>
            <button onClick={handleDownload}>Download Ticket</button>
          </div>
        </div>
      );
    }
    
export default ViewFlightTicketComp