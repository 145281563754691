import React, { useRef, useState, useEffect } from 'react';
import defCover from '../Images/defcover.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlay } from '@fortawesome/free-solid-svg-icons';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faLocationCrosshairs } from '@fortawesome/free-solid-svg-icons';
import { faFingerprint } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import { app } from '../firebase.js';
import { getFirestore } from '@firebase/firestore';
import { collection, query, getDocs, where, getDoc , setDoc, orderBy, deleteDoc} from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import swal from 'sweetalert';
import Table from 'react-bootstrap/Table';
import { doc, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

function ViewBookingsComp() {

  const navigate = useNavigate();
  const location = useLocation();
  const db = getFirestore(app);
  const auth = getAuth();
  const [theexpImage, setexpImage] = useState();
  const [thedesc, setdesc] = useState();
  const [theselectedDay, setselectedDay] = useState();
  const [theselectedTime, setselectedTime] = useState();
  const [thenoOfPassangers, setnoOfPassangers] = useState();
  const [thelistingAmount, setlistingAmount] = useState()
  const [thestatus, setstatus] = useState();

  const [thedurationDesc, setdurationDesc] = useState();
  const [thewriteup, setwriteup] = useState();
  const [theboardingArea, setboardingArea] = useState()
  const [thedropOffArea, setdropOffArea] = useState();
  const [thebookingfee, setbookingfee] = useState();
  const [thevideoUrl, setvideoUrl] = useState();

   // Receive data from the previous page
  useEffect(() => {
    const data = location.state?.myData;
    setMyData(data);
    }, [location.state]);
    const [myData, setMyData] = useState(null); // State to store the received data
  
    console.log(myData)


  useEffect(() => {
    // Fetch driver's location from Firebase when myData changes
    const fetchData = async () => {
      if (myData) {
        try {
          const querySnapshot = await getDocs(query(collection(db, 'Bookings'), where('documentId', '==', myData)));
          querySnapshot.forEach(async (doc) => {

            let expImage = doc.data().expImage;
            const desc = doc.data().sendDesc;
            const selectedDay = doc.data().selectedDay; 
            const selectedTime = doc.data().selectedTime;
            const noOfPassangers = doc.data().noOfPassangers;
            const listingAmount = doc.data().listingAmount;
            const thedocIDbooked = doc.data().thedocIDbooked;
            const status = doc.data().status;

            setexpImage(expImage)
            setdesc(desc)
            setselectedDay(selectedDay)
            setselectedTime(selectedTime)
            setnoOfPassangers(noOfPassangers)
            setlistingAmount(listingAmount)
            setstatus(status)


            try {
              const querySnapshot = await getDocs(query(collection(db, 'experiences'), where('docID', '==', thedocIDbooked)));
              querySnapshot.forEach(async (doc) => {
    
                const durationDesc = doc.data().durationDesc;
                const writeup = doc.data().writeup;
                const boardingArea = doc.data().boardingArea;
                const dropOffArea = doc.data().dropOffArea;
                const pricePerPerson = doc.data().pricePerPerson;
                const videoUrl = doc.data().videoUrl;
    
                setdurationDesc(durationDesc)
                setwriteup(writeup)
                setboardingArea(boardingArea)
                setdropOffArea(dropOffArea)
                setvideoUrl(videoUrl)
    
                const theprice = parseInt(pricePerPerson);
                setbookingfee(theprice)
    
              });
            } catch (error) {
              console.error('Error fetching data from Firebase:', error);
            }



          });
        } catch (error) {
          console.error('Error fetching data from Firebase:', error);
        }
      }
    };

    // Initial fetch
    fetchData();
  }, [myData, db]);

  ///



  const formatPrice = (price) => {
    // Convert price to a number if it’s not already
    const num = Number(price);
    // Check if the conversion resulted in NaN
    if (isNaN(num)) {
      return 'N/A'; // Fallback for invalid numbers
    }
    return num.toLocaleString();
  };

  function viewElectronicTicket(){
    navigate('/view-experience-ticket', { state: { myData: myData } });
  }

  function playTheVideo(){
    navigate('/play-video', { state: { myData: thevideoUrl } });
  }



  return (
    <div className='bookingCenter'>

      <div className='expBookingDiv'>
        <img src={theexpImage} alt="" />
      </div>

      <div className='expBookingDiv2'>
        <div>
          <h2>{thedesc}</h2>
          <p>{thedurationDesc}</p>
        </div>
          <FontAwesomeIcon icon={faCirclePlay} className='expBookingIcon' onClick={() => playTheVideo()} />
      </div>

      <p className='expBookP'>{thewriteup}</p>

      <div className='expBookFeatureDiv'>
        <p><FontAwesomeIcon icon={faCamera }/> Free photographer included</p>
        <p><FontAwesomeIcon icon={faLocationDot }/> Boarding area is at {theboardingArea}</p>
        <p><FontAwesomeIcon icon={faLocationCrosshairs }/> Dropoff area is at {thedropOffArea}</p>
        <p><FontAwesomeIcon icon={faFingerprint }/> Kindly carry your National ID/Passport</p>
      </div>

      <br />
      <div className='expBookFeatureDiv'>
        <p>Flight Date: {theselectedDay} at {theselectedTime}</p>
        <p>Total Passengers: {thenoOfPassangers}</p>
        <p>Total Amount Paid: KES. {formatPrice(thelistingAmount)}</p>
      </div>

     
      <div className='bookButtonCont'>
          <div>
            <p>{thestatus}</p>
          </div>
          <button onClick={() => viewElectronicTicket()}>View Electronic Ticket</button>
        </div>

    </div>
  )
}

export default ViewBookingsComp