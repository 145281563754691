import React, { useRef, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Table from 'react-bootstrap/Table';
import { getFirestore } from "@firebase/firestore";
import { app } from '../firebase.js';
import { collection, doc, orderBy, setDoc,query, getDocs, deleteDoc, where, updateDoc} from "@firebase/firestore"; 
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import swal from 'sweetalert';
import Spinner from 'react-bootstrap/Spinner';

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {useNavigate } from "react-router-dom";



function HeliCharters() {

  const [studentsList, setStudentList] = useState([]);
  const db = getFirestore(app);
  const auth = getAuth();
  const storage = getStorage();
  let navigate = useNavigate();
  const [loading, setLoading] = useState(true);



  useEffect(() => {
    const fetchData = async () => {
      try {
        const q = query(
          collection(db, 'flights'),
          where('Availability', '==', 'available'),
          where('aircraftType', '==', 'helicopter'),
          orderBy('price', 'asc')
        );
        const querySnapshot = await getDocs(q);
        let studentsItem = [];
        querySnapshot.forEach((doc) => {
          studentsItem.push({ id: doc.id, ...doc.data() });
        });
        setStudentList(studentsItem);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [db]);

    function viewCase(documentId){
      navigate('/charter-plane', { state: { myData: documentId } });
    }


    const formatPrice = (price) => {
      // Convert price to a number if it’s not already
      const num = Number(price);
      // Check if the conversion resulted in NaN
      if (isNaN(num)) {
        return 'N/A'; // Fallback for invalid numbers
      }
      return num.toLocaleString();
    };
  
    if (loading) {
      return (
        <div className="spinnerContainer">
          <Spinner animation="border" />
        </div>
      );
    }
  
  
    if (!studentsList.length) {
      return <div>No available helicopter charters found.</div>;
    }

  return (
    <div className='flightCont'>

        {studentsList.map((mystudent) => (
                <div className='experienceDiv' key={Math.random()} onClick={() => viewCase(mystudent.docID)}>
                  <div className='experienceDivImageCont'>
                    <img src={mystudent.flightImage} alt="" />
                  </div>
                  <h5>{mystudent.aircraftName}</h5>
                  <p>{mystudent.Capacity}</p>
                  <p>{mystudent.aircraftType}</p>
                  <p>KES. {formatPrice(mystudent.price)} per hour</p>
                </div>
                ))}
            <h5 className='hidethisshit'>.</h5>
           
    </div>
  )
}

export default HeliCharters