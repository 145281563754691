import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

function ViewVideoComp() {

    const navigate = useNavigate();
    const location = useLocation();

  // Receive data from the previous page
  useEffect(() => {
    const data = location.state?.myData;
    setMyData(data);
    }, [location.state]);
    const [myData, setMyData] = useState(null); // State to store the received data
  
    console.log(myData)
  return (
    <div className='viewVideoComp'>
        <video src={myData} controls autoPlay></video>
    </div>
  )
}

export default ViewVideoComp