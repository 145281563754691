import React, { useRef, useState, useEffect } from 'react';
import { getFirestore } from '@firebase/firestore';
import { app } from '../firebase.js';
import Spinner from 'react-bootstrap/Spinner';
import {
  collection,
  query,
  orderBy,
  startAfter,
  limit,
  getDocs,
} from '@firebase/firestore'; // Ensure correct imports

function ExploreComp() {
  const [studentsList, setStudentList] = useState([]);
  const [loading, setLoading] = useState(true);
  const db = getFirestore(app);
  const observerRef = useRef(null); // Reference for the last visible video element
  const [batchSize] = useState(3); // Number of videos to load per batch

  useEffect(() => {
    fetchData(); // Initial data fetch
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    try {
      const q = query(collection(db, 'Videos'), orderBy('myTimestamp', 'desc'));
      const querySnapshot = await getDocs(q);
      let studentsItem = [];
      querySnapshot.forEach((doc) => {
        studentsItem.push({ id: doc.id, ...doc.data() });
      });
      setStudentList(studentsItem);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const fetchMoreVideos = async () => {
    try {
      const lastVideo = studentsList[studentsList.length - 1];
      const q = query(
        collection(db, 'Videos'),
        orderBy('myTimestamp', 'desc'),
        startAfter(lastVideo.myTimestamp), // Correctly use startAfter
        limit(batchSize) // Correctly use limit
      );
      const querySnapshot = await getDocs(q);
      let newStudentsItem = [];
      querySnapshot.forEach((doc) => {
        newStudentsItem.push({ id: doc.id, ...doc.data() });
      });
      setStudentList([...studentsList, ...newStudentsItem]);
    } catch (error) {
      console.error('Error fetching more data:', error);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          fetchMoreVideos();
        }
      },
      { threshold: 1 }
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [studentsList]); // Only observe changes to studentsList

  if (loading) {
    return (
      <div className="spinnerContainer">
        <Spinner animation="border" />
      </div>
    );
  }

  if (!studentsList.length) {
    return <div>No available videos found.</div>;
  }

  return (
    <div className="mainContentDiv">
      <h1 className="heroText">Explore</h1>

      {studentsList.map((mystudent, index) => (
        <div
          className="videoItem"
          key={mystudent.id}
          ref={index === studentsList.length - 1 ? observerRef : null}
        >
          <video
            src={mystudent.videoUrl}
            controls={false} // Initially hide controls
            onMouseEnter={(e) => (e.target.controls = true)} // Show controls on hover
            onMouseLeave={(e) => (e.target.controls = false)} // Hide controls on mouse leave
          ></video>
        </div>
      ))}
    </div>
  );
}

export default ExploreComp;
