import React from 'react'
import LeftBar from '../Components/LeftBar'
import NavBar from '../Components/NavBar'
import './Dash.css'
import RightBar from '../Components/RightBar'
import ProfileComp from './ProfileComp'

function Profile() {
  return (
    <div className='contentMain'> 
        <LeftBar/>
        <div className='theRightOne'> 
        <ProfileComp/>  
    </div>
    <RightBar/>
    </div>
  )
}

export default Profile