import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import { app } from '../firebase.js';
import { getFirestore, collection, query, getDocs, where, doc, addDoc, setDoc, Timestamp } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import { useNavigate, useLocation } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import axios from 'axios';
import swal from 'sweetalert';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';


function MakePaymentComp() {
  const navigate = useNavigate();
  const location = useLocation();
  const db = getFirestore(app);
  const auth = getAuth();

  const [toLocation, setToLocation] = useState({ lat: 0, lng: 0 });
  const [toAddress, setToAddress] = useState('');

  const whereImgoing = toAddress;

  const [myData, setMyData] = useState(null);
  const [thebookingfee, setBookingFee] = useState(0);
  const [error, setError] = useState('');
  const [theOriginalFee, setOriginalFee] = useState(0);
  const [theaircraftName, setaircraftName] = useState('');
  const [theflightImage, setflightImage] = useState('');
  const [thebookingType, setbookingType] = useState('');
  const [formData, setFormData] = useState({
    date: '',
    time: '',
    hours: '1', // Default hours to 1
    passengers: '',
    description: '',
    phone: ''
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleToSelect = async (address) => {
    setToAddress(address);
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      setToLocation(latLng);
    } catch (error) {
      console.error('Error', error);
    }
  };

  useEffect(() => {
    const data = location.state?.myData;
    setMyData(data);
  }, [location.state]);

  useEffect(() => {
    const fetchData = async () => {
      if (myData) {
        try {
          const querySnapshot = await getDocs(query(collection(db, 'flights'), where('docID', '==', myData)));
          querySnapshot.forEach((doc) => {
            const price = doc.data().price;
            const aircraftName = doc.data().aircraftName;
            const flightImage = doc.data().flightImage;
            const aircraftType = doc.data().aircraftType;

            let bookingType = '';

            if (aircraftType === 'fixed wing') {
              bookingType = 'Small Aircraft Charter';
            } else if (aircraftType === 'fixed wing2') {
              bookingType = 'Private Jet Charter';
            } else if (aircraftType === 'helicopter') {
              bookingType = 'Helicopter Charter';
            }

            setOriginalFee(price);
            setaircraftName(aircraftName);
            setflightImage(flightImage);
            setbookingType(bookingType); // Set bookingType here

            // Calculate the initial booking fee based on the default hours
            const initialHours = formData.hours;
            const initialBookingFee = parseInt(price) * 10 / 100 * initialHours;
            setBookingFee(1);
          });
        } catch (error) {
          console.error('Error fetching data from Firebase:', error);
        }
      }
    };
    fetchData();
  }, [myData, db, formData.hours]);

  const formatPrice = (price) => {
    const num = Number(price);
    return isNaN(num) ? 'N/A' : num.toLocaleString();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));

    // If the hours field is updated, recalculate the booking fee
    if (name === 'hours') {
      const newHours = value;
      const newBookingFee = parseInt(theOriginalFee) * 10 / 100 * newHours;
      setBookingFee(newBookingFee);
    }
  };

  const formatPhoneNumber = (phone) => {
    let cleaned = phone.replace(/\D/g, '');
    if (cleaned.startsWith('0')) {
      cleaned = `254${cleaned.slice(1)}`;
    } else if (cleaned.startsWith('254')) {
      // Already in the correct format
    } else if (cleaned.length === 10 && !cleaned.startsWith('254')) {
      cleaned = `254${cleaned}`;
    }
    return cleaned;
  };

  const validateForm = () => {
    const { date, time, hours, passengers, description, phone } = formData;
    if (!date || !time || !hours || !passengers || !description || !phone) {
      setError('All fields are required. Please fill in all fields.');
      return false;
    }
    const formattedPhone = formatPhoneNumber(phone);
    if (formattedPhone.length < 12) {
      setError('Phone number must be valid and at least 10 digits long.');
      return false;
    }
    setError('');
    return true;
  };

  function generateTransactionRef(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }

    return result;
  }

  const sendSms = async (message, phoneNumber, transRef) => {
    const url = 'https://us-central1-pepea-a9eaa.cloudfunctions.net/hellosms/sendsms';
    const requestBody = {
      message,
      phoneNumber,
      transRef,
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        const responseData = await response.json();
        console.log(responseData);
        return true;
      } else {
        console.error(`HTTP error! Status: ${response.status}`);
        return false;
      }
    } catch (error) {
      console.error('Error:', error);
      return false;
    }
  };

  const sendStkPush = async (phone, amount) => {
    try {
      const response = await axios.post('https://us-central1-pepea-a9eaa.cloudfunctions.net/api/stkpush', {
        phone,
        amount
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const data = response.data;
      console.log('STK Push Response:', data);

      if (data.ResponseCode === '0') {
        // STK Push sent successfully
        swal({
          title: 'STK Push Sent',
          text: data.CustomerMessage,
          icon: 'success',
          buttons: {
            confirm: {
              text: 'I have paid',
              value: 'confirm',
              className: 'confirm-button'
            },
            cancel: 'I haven\'t paid'
          },
          content: {
            element: 'div',
            attributes: {
              innerHTML: '<style>.confirm-button { position: relative; }</style>'
            }
          }
        }).then((value) => {
          if (value === 'confirm') {
            setIsLoading(true); // Show loading spinner on the "I have paid" button

            verifyPayment(data.CheckoutRequestID).then(() => {
              setIsLoading(false); // Hide loading spinner after payment confirmation
            });
          } else {
            swal('Please complete the payment and try again.');
          }
        });
      } else {
        swal('Failed to send STK Push Request');
      }
    } catch (error) {
      console.error('Error sending STK Push request:', error);
      swal('Error sending STK Push Request. Please try again.');
    }
  };

  const verifyPayment = async (queryCode) => {
    try {
      const response = await axios.post('https://us-central1-pepea-a9eaa.cloudfunctions.net/api/query', {
        queryCode
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const data = response.data;
      console.log('Payment Query Response:', data);

      if (data.ResultCode === '0') {
        // Payment verified successfully, proceed to save to Firebase
        const formattedPhone = formatPhoneNumber(formData.phone);
        const currentDate = Timestamp.now();
        const transactionRef = generateTransactionRef(7);

        const newDataRef = doc(collection(db, "Bookings"));
        setDoc(newDataRef, {
      
          CheckoutRequestID: data.CheckoutRequestID,
          TripDesc: formData.description,
          amountPaid: thebookingfee,
          bookingType: thebookingType,
          editphonenumber: formData.phone,
          expImage: theflightImage,
          listingAmount: theOriginalFee,
          noOfPassangers: formData.passengers,
          numberOfHours: formData.hours,
          phonenumber: formattedPhone,
          selectedDay: formData.date,
          selectedTime: formData.time,
          sendDesc: theaircraftName,
          status: 'Pending Trip',
          thedocIDbooked: myData,
          timestamp: currentDate,
          transactionRef,
          userId: auth.currentUser.uid,
          whereto: whereImgoing,
          documentId:newDataRef.id
        }).then(()=>{
          // Add the booking to the Firestore
          // Send SMS
          const smsMessage = `Your booking for ${theaircraftName} is confirmed. Amount: KES ${formatPrice(thebookingfee)}. Ref: ${transactionRef}. Download the PDF Electronic Ticket and present before boarding.`;
          const smsSent =  sendSms(smsMessage, formattedPhone, transactionRef);

          if (smsSent) {
            swal('Payment Verified', 'Booking completed successfully. Download the PDF Electronic Ticket and present it before boarding.', 'success').then(() => {
              navigate('/trips');
            });
          } else {
            swal('Payment Verified', 'Booking completed. Download the PDF Electronic Ticket and present it before boarding', 'success').then(() => {
              navigate('/trips');
            });
          }
        });

 
      } else {
        swal('Payment verification failed. Please try again.');
      }
    } catch (error) {
      console.error('Error verifying payment:', error);
      swal('Error verifying payment. Please try again.');
    }
  };

  const makePayment = () => {
    if (validateForm()) {
      const formattedPhone = formatPhoneNumber(formData.phone);
      console.log('Formatted Phone:', formattedPhone);
      console.log('Form Data:', { ...formData, phone: formattedPhone });
      setIsLoading(true); // Show loading spinner on the "Make Payment" button
      sendStkPush(formattedPhone, thebookingfee.toString());
    }
  };

  return (
    <div className='bookingCenter'>
      <Form>
        <Form.Group className="mb-3" controlId="date">
          <Form.Label>Select a date</Form.Label>
          <Form.Control
            type="date"
            name="date"
            value={formData.date}
            onChange={handleInputChange}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="time">
          <Form.Label>Select time</Form.Label>
          <Form.Control
            type="time"
            name="time"
            value={formData.time}
            onChange={handleInputChange}
          />
        </Form.Group>

        <p>Flight Date</p>
        <Alert className="customAlert">
          <p>Time: {formData.time}</p>
          <p>Date: {formData.date}</p>
        </Alert>

        <Form.Group className="mb-3" controlId="hours">
          <Form.Label>Number of Hours</Form.Label>
          <Form.Control
            type="number"
            name="hours"
            value={formData.hours}
            onChange={handleInputChange}
          />
        </Form.Group>


        <Form.Group className="mb-3" controlId="toLocation">
        <Form.Label>Where to?</Form.Label>
        <PlacesAutocomplete value={toAddress} onChange={setToAddress} onSelect={handleToSelect}>
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div>
              <Form.Control {...getInputProps({ placeholder: 'Enter and select a location' })} />
              <div>
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => (
                  <div key={suggestion.id} {...getSuggestionItemProps(suggestion)} className='theSuggestions'>
                    {suggestion.description}
                  </div>
                ))}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </Form.Group>


        <Form.Group className="mb-3" controlId="passengers">
          <Form.Label>Number of Passengers</Form.Label>
          <Form.Control
            type="number"
            name="passengers"
            value={formData.passengers}
            onChange={handleInputChange}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="description">
          <Form.Label>Describe the Trip</Form.Label>
          <Form.Control
            type="text"
            name="description"
            value={formData.description}
            onChange={handleInputChange}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="phone">
          <Form.Label>Phone number to receive MPESA Payment prompt</Form.Label>
          <Form.Control
            type="number"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
          />
        </Form.Group>

        {error && <Alert variant="danger">{error}</Alert>}

        <div className='bookButtonCont'>
          <div>
            <p>10% Booking Fee</p>
            <h6>KES. {formatPrice(thebookingfee)}</h6>
          </div>
          <Button onClick={makePayment} disabled={isLoading}>
            {isLoading ? (
              <>
                <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                <span> Processing...</span>
              </>
            ) : (
              "Make Payment"
            )}
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default MakePaymentComp;
