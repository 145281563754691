import React, { useState, useEffect } from 'react';
import { GoogleMap, useLoadScript, Marker, DirectionsRenderer } from '@react-google-maps/api';
import Form from 'react-bootstrap/Form';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner'; 
import medEmergency from '../Images/medicalemergency.png'
import swal from 'sweetalert';
import {app} from '../firebase.js'
import { getFirestore } from "@firebase/firestore";
import { getAuth, onAuthStateChanged , signOut} from "firebase/auth";
import { doc, getDocFromCache,getDoc } from "firebase/firestore";


function ExpressComp() {

  const [city, setCity] = useState('Nairobi'); // State for the user's city
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [MyDistance, setMyDistance] = useState("O km")
  const [mythefinalTotal, setthefinalTotal] = useState(0)
  const db = getFirestore(app);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyBJexYTRkvopO3_UwmLSG4nxTY7q_oDroY', // Add your Google Maps API key here
  });


  //places autocomplete
  const [toLocation, setToLocation] = useState({ lat: 0, lng: 0 });
  const [toAddress, setToAddress] = useState('');
  const [theemergencyPerKm, setemergencyPerKm] = useState(0)
  const [theemergencyBaseFare, setemergencyBaseFare] = useState(0)


  const handleToSelect = async (address) => {
    setToAddress(address);
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      setToLocation(latLng);
    } catch (error) {
      console.error('Error', error);
    }
  };

  

  const [fromLocation, setFromLocation] = useState({ lat: 0, lng: 0 });
  const [fromAddress, setFromAddress] = useState('');
  const handleFromSelect = async (address) => {
    setFromAddress(address);
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      setFromLocation(latLng);
    } catch (error) {
      console.error('Error', error);
    }
  };


  const darkTheme = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#212121"
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#212121"
        }
      ]
    },
    {
      "featureType": "administrative",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "administrative.country",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "administrative.locality",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#bdbdbd"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#181818"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#1b1b1b"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#2c2c2c"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#8a8a8a"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#373737"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#3c3c3c"
        }
      ]
    },
    {
      "featureType": "road.highway.controlled_access",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#4e4e4e"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#000000"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#3d3d3d"
        }
      ]
    }
  ];


  const docRef = doc(db, "Express", "pricing");

  getDoc(docRef)
    .then((document) => {
      if (document.exists()) {
        console.log("Cached document data:", document.data());
        let emergencyBaseFare = document.data().emergencyBaseFare;
        let emergencyPerKm = document.data().emergencyPerKm;

        console.log(emergencyPerKm)
        setemergencyPerKm(emergencyPerKm)
        setemergencyBaseFare(emergencyBaseFare)


      } else {
        console.log("No such document in the cache!");
      }
    })
    .catch((e) => {
      console.log("Error getting cached document:", e);
    });



  // Helper function to calculate distance using the Haversine formula
  const calculateDistance = (loc1, loc2) => {
    const toRadians = (deg) => deg * (Math.PI / 180);

    const R = 6371; // Radius of the Earth in km
    const dLat = toRadians(loc2.lat - loc1.lat);
    const dLng = toRadians(loc2.lng - loc1.lng);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(loc1.lat)) * Math.cos(toRadians(loc2.lat)) *
      Math.sin(dLng / 2) * Math.sin(dLng / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in km
  };

  // Helper function to get distance using Google Distance Matrix API
  const getDistanceMatrix = async (origins, destinations) => {
    const service = new window.google.maps.DistanceMatrixService();
    return new Promise((resolve, reject) => {
      service.getDistanceMatrix(
        {
          origins,
          destinations,
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (response, status) => {
          if (status === 'OK') {
            resolve(response);
          } else {
            reject(status);
          }
        }
      );
    });
  };


  async function getStarted(){
    //first lets check if the locations are in Nairobi
    //toLocation, fromLocation

    //second lets check the distance between the two location

    //third lets pull up a div containing rides 
    setIsLoading(true);

    const nairobiBounds = {
      north: -1.098,
      south: -1.417,
      east: 37.073,
      west: 36.640,
    };
  
    // Function to check if a location is within Nairobi boundaries
    const isWithinNairobi = (location) => {
      return (
        location.lat <= nairobiBounds.north &&
        location.lat >= nairobiBounds.south &&
        location.lng <= nairobiBounds.east &&
        location.lng >= nairobiBounds.west
      );
    };
  
    // Check if both locations are within Nairobi
    const isToLocationInNairobi = isWithinNairobi(toLocation);
    const isFromLocationInNairobi = isWithinNairobi(fromLocation);
  
    if (!isToLocationInNairobi || !isFromLocationInNairobi) {
     // alert("Both locations must be within Nairobi.");
      swal('Location Error!', 'Both locations must be within Nairobi', 'error');
      setIsLoading(false); 
      return;
    }
  
    // Proceed with other operations if locations are within Nairobi
    // 1. Check the distance between the two locations
    // 2. Pull up a div containing rides
  
    // Example: Check distance (implement distance check)
    // Example: Show available rides (implement showing rides)
  
    console.log('Both locations are in Nairobi. Proceeding...');

    const distance = calculateDistance(fromLocation, toLocation);
    console.log(`Distance: ${distance.toFixed(2)} km`);

    // Using Google Distance Matrix API to get distance and duration
    try {
      const response = await getDistanceMatrix(
        [new window.google.maps.LatLng(fromLocation.lat, fromLocation.lng)],
        [new window.google.maps.LatLng(toLocation.lat, toLocation.lng)]
      );

      const distanceText = response.rows[0].elements[0].distance.text;
      const durationText = response.rows[0].elements[0].duration.text;

      const calcDistance = response.rows[0].elements[0].distance.value;

      console.log(`Google API Distance: ${distanceText}`);
      console.log(`Google API Duration: ${durationText}`);

      console.log(calcDistance)

      // Example: Proceed with distance and duration
      // Example: Show available rides (implement showing rides)
      setMyDistance(distanceText)
      setIsLoading(false);
      setIsVisible(true);
      
      
      //calclulate the price here
      const perKmFlight = parseInt(theemergencyPerKm);
      const theNaseFare = parseInt(theemergencyBaseFare);

      const converttokm = calcDistance/1000

      //
      const thefinalTotal = theNaseFare + (perKmFlight * converttokm)

      setthefinalTotal(thefinalTotal)


      

    } catch (error) {
      console.error('Error fetching distance matrix:', error);
      setIsLoading(false); 
    }



  }


  const formatPrice = (price) => {
    // Convert price to a number if it’s not already
    const num = Number(price);
    // Check if the conversion resulted in NaN
    if (isNaN(num)) {
      return 'N/A'; // Fallback for invalid numbers
    }
    return num.toLocaleString();
  };


  return (
    <div className='expressMapCont'>
      {/* <h1 className='heroText'>Express</h1> */}

      <div className='expressLocationsCont'>
      <Form.Group className="mb-3 mapDropDownExplore" controlId="toLocation">
        <PlacesAutocomplete value={fromAddress} onChange={setFromAddress} onSelect={handleFromSelect}>
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div>
              <Form.Control {...getInputProps({ placeholder: 'Where from?' })} />
              <div>
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => (
                  <div key={suggestion.id} {...getSuggestionItemProps(suggestion)} className='theSuggestions'>
                    {suggestion.description}
                  </div>
                ))}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </Form.Group>

      <Form.Group className="mb-3 mapDropDownExplore" controlId="toLocation">
        {/* <Form.Label>Where to?</Form.Label> */}
        <PlacesAutocomplete value={toAddress} onChange={setToAddress} onSelect={handleToSelect}>
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div>
              <Form.Control {...getInputProps({ placeholder: 'Where to?' })} />
              <div>
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => (
                  <div key={suggestion.id} {...getSuggestionItemProps(suggestion)} className='theSuggestions'>
                    {suggestion.description}
                  </div>
                ))}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </Form.Group>
      </div>

      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={{ height: "90vh", width: "100%" }}
          center={{ lat: -1.286389, lng: 36.817223 }} // Coordinates for Nairobi
          zoom={12}
          options={{
            styles: darkTheme,
            mapTypeControl: false,
            fullscreenControl: false,
            streetViewControl: false,
            zoomControl: false,
          }} 
          className="myNewMap"
        >
          <Marker position={{ lat: -1.286389, lng: 36.817223 }} />
        </GoogleMap>
      ) : (
        <div>Loading map...</div>
      )}

      {isVisible && (
        <div className='flightscontainer'>
          <div className='flightscontainerTop'>
            <img src={medEmergency}  className='medEmerIcon' alt="" />
            <div className='flightscontainerTop2'>
              <h6>Medical Emergency</h6>
              <h5>{MyDistance}</h5>
            </div>
          </div>

          <div className='flightscontainerTop3'>
            <h4>KES. {formatPrice(mythefinalTotal)}</h4>
            <button className='btn btn-sm btn-primary'>Select</button>
          </div>
        </div>
      )}

      <div className='bookButtonContExp'>
          <div>
            <p>Medical Emergencies</p>
            <h6>{MyDistance}</h6>
          </div>
          <Button onClick={getStarted}  disabled={isLoading}>
            {isLoading ? (
              <>
                <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                <span> Processing....</span>
              </>
            ) : (
              "Get Started"
            )}
          </Button>
        </div>

    </div>
  )
}

export default ExpressComp


