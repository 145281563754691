import React from 'react'
import LeftBar from '../Components/LeftBar'
import NavBar from '../Components/NavBar'
import './Dash.css'
import RightBar from '../Components/RightBar'
import ViewVideoComp from './ViewVideoComp'

function ViewVideo() {
  return (
    <div className='contentMain'> 
    <LeftBar/>
    <div className='theRightOne'> 
      <ViewVideoComp/>  
   </div>
   <RightBar/>
  </div>
  )
}

export default ViewVideo