import React from 'react'
import LeftBar from '../Components/LeftBar'
import NavBar from '../Components/NavBar'
import './Dash.css'
import RightBar from '../Components/RightBar'
import ViewExpTicketComp from './ViewExpTicketComp'

function ViewExpTicket() {
  return (
    <div className='contentMain'> 
    <LeftBar/>
    <div className='theRightOne'> 
      <ViewExpTicketComp/>  
   </div>
   <RightBar/>
  </div>
  )
}

export default ViewExpTicket