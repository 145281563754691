import React, { useRef, useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import { app } from '../firebase.js';
import { getFirestore } from '@firebase/firestore';
import { collection, query, getDocs, where, getDoc , setDoc, orderBy, deleteDoc} from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from "firebase/auth";

import swal from 'sweetalert';
import { useNavigate, useLocation } from 'react-router-dom';
import Table from 'react-bootstrap/Table';

import { doc, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGauge } from '@fortawesome/free-solid-svg-icons';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import defCover from '../Images/defcover.jpg'
import { faHelicopter} from '@fortawesome/free-solid-svg-icons'



function FlightBookingComp() {

  const navigate = useNavigate();
  const location = useLocation();
  const db = getFirestore(app);
  const auth = getAuth();

  // Receive data from the previous page
  useEffect(() => {
  const data = location.state?.myData;
  setMyData(data);
  }, [location.state]);
  const [myData, setMyData] = useState(null); // State to store the received data

  console.log(myData)

  ////
  const [theaircraftName, setaircraftName] = useState()
  const [theflightImage, setflightImage] = useState();
  const [theCapacity, setCapacity] = useState();
  const [theflightDescription, setflightDescription] = useState();
  const [theSpeed, setSpeed] = useState();
  const [theEndurance, setEndurance] = useState();
  const [theTakeOffArea, setTakeOffArea] = useState()
  const [thebookingfee, setbookingfee] = useState()

  useEffect(() => {
      // Fetch driver's location from Firebase when myData changes
      const fetchData = async () => {
        if (myData) {
          try {
            const querySnapshot = await getDocs(query(collection(db, 'flights'), where('docID', '==', myData)));
            querySnapshot.forEach(async (doc) => {
              let aircraftName = doc.data().aircraftName;
              const flightImage = doc.data().flightImage
              const Capacity = doc.data().Capacity;
              const flightDescription = doc.data().flightDescription;
              const Speed = doc.data().Speed;
              const Endurance = doc.data().Endurance;
              const TakeOffArea = doc.data().TakeOffArea;
              const price = doc.data().price;
              

              setaircraftName(aircraftName)
              setflightImage(flightImage)     
              setCapacity(Capacity) 
              setflightDescription(flightDescription) 
              setSpeed(Speed)
              setEndurance(Endurance)
              setTakeOffArea(TakeOffArea)

              // calculate 10% booking fee

              const bookingfee = parseInt(price) * 10/100;

              setbookingfee(bookingfee)

            });
          } catch (error) {
            console.error('Error fetching data from Firebase:', error);
          }
        }
      };
  
      // Initial fetch
      fetchData();
    }, [myData, db]);
  
    ///


  //end


  const formatPrice = (price) => {
    // Convert price to a number if it’s not already
    const num = Number(price);
    // Check if the conversion resulted in NaN
    if (isNaN(num)) {
      return 'N/A'; // Fallback for invalid numbers
    }
    return num.toLocaleString();
  };


  function makePayment(){
    navigate('/make-payment', { state: { myData: myData } });
  }


  return (
    <div className='bookingCenter'>
      <div className='bookigDiv' >
        <img src={theflightImage} className='bookingImage' alt="" />
      </div>
      <h4>{theaircraftName}</h4>
      <p className='bookingP'>{theCapacity}</p>
 
      <p className='bookingP'>{theflightDescription}</p>
      <hr />

      <div className='bookingIcons'>
        <FontAwesomeIcon icon={faGauge} className='thebookIcon' />
        <div>
          <h6>Speed/Distane</h6>
          <p>{theSpeed}</p>
        </div>
      </div>

      <div className='bookingIcons'>
        <FontAwesomeIcon icon={faHelicopter} className='thebookIcon' />
        <div>
          <h6>Endurance</h6>
          <p>{theEndurance}</p>
        </div>
      </div>

      <div className='bookingIcons'>
        <FontAwesomeIcon icon={faLocationDot} className='thebookIcon' />
        <div>
          <h6>Boarding Area</h6>
          <p>{theTakeOffArea}</p>
        </div>
      </div>
        

        <div className='bookButtonCont'>
          <div>
            <p>10% Booking Fee</p>
            <h6>KES {formatPrice(thebookingfee)}</h6>
          </div>
          <button onClick={() => makePayment()}>Book Now</button>
        </div>
    </div>
  )
}

export default FlightBookingComp