import React from 'react'
import LeftBar from '../Components/LeftBar'
import NavBar from '../Components/NavBar'
import './Dash.css'
import RightBar from '../Components/RightBar'
import ConfirmBookingComp from './ConfirmBookingComp'

function ConfirmBooking() {
  return (
    <div className='contentMain'> 
      <LeftBar/>
      <div className='theRightOne'> 
        <ConfirmBookingComp/>  
     </div>
     <RightBar/>
    </div>
  )
}

export default ConfirmBooking