import React from 'react'
import LeftBar from '../Components/LeftBar'
import NavBar from '../Components/NavBar'
import './Dash.css'
import RightBar from '../Components/RightBar'
import MakePaymentComp from './MakePaymentComp'

function MakePayment() {
  return (
    <div className='contentMain'> 
        <LeftBar/>
        <div className='theRightOne'> 
        <MakePaymentComp/>  
    </div>
    <RightBar/>
    </div>
  )
}

export default MakePayment