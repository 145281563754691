import React, { useRef, useState } from 'react';
import './Auth.css';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate, Link } from 'react-router-dom';
import swal from 'sweetalert';
import { app } from '../firebase.js';
import { getFirestore } from '@firebase/firestore';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

import logo from '../Images/logoblack.svg';

function Login() {
  let navigate = useNavigate();
  const emailRef = useRef();
  const passwordRef = useRef();

  const [showProgress, setShowProgress] = useState(false);
  const [showSignInbtn, setShowSignInbtn] = useState(true);

  async function loginUser() {
    const email = emailRef.current.value;
    const password = passwordRef.current.value;

    setShowProgress(true);
    setShowSignInbtn(false);

    const auth = getAuth();
    const db = getFirestore(app);

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);

      // Check if the user's email is verified
      const isVerified = userCredential.user.emailVerified;

      if (!isVerified) {
        setShowProgress(false);
        setShowSignInbtn(true);
        swal('Verification Error!', 'Please verify your email before signing in.', 'error');
        return;
      }

      // Signed in
      const user = userCredential.user;
      let userID = userCredential.user.uid;
      // ...
      navigate('/home');
    } catch (error) {
      setShowProgress(false);
      setShowSignInbtn(true);

      const errorCode = error.code;
      const errorMessage = error.message;
      swal('Login Error!', '' + errorMessage + '', 'error');
    }
  }

  return (
    <div className='mainAuth'>
      <div className='AuthLeft'>
        <div className='authleftCont'>
          <video
            autoPlay
            muted
            loop
            src='https://res.cloudinary.com/ubunifu/video/upload/v1704724179/pexels-ana-benet-8242842_1080p_mlammz.mp4'
          ></video>
          <div className='authLeftOpacity'></div>
        </div>
      </div>

      <div className='AuthRight'>
        <img src={logo} alt='' className='logoAuth' />
        <h3>Login</h3>
        <br/>
        <Form.Group className='mb-3' controlId='formBasicEmail'>
          <Form.Label>Email address</Form.Label>
          <Form.Control type='email' placeholder='Enter email' ref={emailRef} />
        </Form.Group>

        <Form.Group className='mb-3' controlId='formBasicPassword'>
          <Form.Label>Password</Form.Label>
          <Form.Control type='password' placeholder='Password' ref={passwordRef} />
        </Form.Group>

        {showSignInbtn && (
          <Button variant='primary' type='submit' className='btnLogin' onClick={loginUser}>
            Sign in
          </Button>
        )}

        {showProgress && (
          <Button variant='dark' disabled className='btnLogin'>
            <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
            <span> Signing in...</span>
          </Button>
        )}

        <Link to='/signup' className='signuptext '>
          Don't have an account? Sign Up
        </Link>
      </div>
    </div>
  );
}

export default Login;
