import React, { useRef, useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell} from '@fortawesome/free-solid-svg-icons'
import { faChevronRight} from '@fortawesome/free-solid-svg-icons'
import { faLocationDot} from '@fortawesome/free-solid-svg-icons'
import { faPlane} from '@fortawesome/free-solid-svg-icons'
import { faHelicopter} from '@fortawesome/free-solid-svg-icons'
import { faVideo} from '@fortawesome/free-solid-svg-icons'

//routers
import {useNavigate } from "react-router-dom";
import {Link } from "react-router-dom";
import {NavLink } from "react-router-dom";

import logo from '../Images/logowhite.svg'
import androidApp from '../Images/androiddownload.png'
import appleApp from '../Images/iphone.jpeg'

import {app} from '../firebase.js'
import { getFirestore } from "@firebase/firestore";
import { getAuth, onAuthStateChanged , signOut} from "firebase/auth";
import { collection, doc, setDoc,query, getDocs, deleteDoc, where} from "@firebase/firestore"; 

import accInfo from '../Images/nyawawa.png'
import logoutInfo from '../Images/ic_privacy.png'
import coverImage from '../Images/defcover.jpg'

import swal from 'sweetalert';


function ProfileComp() {

  let navigate = useNavigate();
  //navigate("/") 

  const db = getFirestore(app);
  const [loggedInUser, setloggedInUser] = useState("");
  const [userProfPic, setuserProfPic] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userEmail, setUserEmail] = useState("");

    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        // ...

        const j = query(
          collection(db, "users"),
          where("userId", "==", uid)
        );
        getDocs(j).then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let fullname = doc.data().fullname;
            let profileImage = doc.data().profileImage;
            let phone = doc.data().phone;
            let email = doc.data().email;
            setloggedInUser(fullname)
            setuserProfPic(profileImage)
            setUserPhone(phone)
            setUserEmail(email)
          });
      
        });


      } else {
        // User is signed out
        navigate("/")  
      }
    });
  
  function logoutTheUser(){
    const auth = getAuth();
  
    swal({
      title: "Sign out?",
      text: "Are you sure you want to sign out",
      icon: "warning",
      button: "Sure",
    }).then((value) => {
      signOut(auth).then(() => {
        // Sign-out successful.
            navigate("/")  
        }).catch((error) => {
    
        });
    });
}

  function navigateToAccInfo(){
    navigate('/account-info');
  }

  function navigateToEditAcc(){
    navigate('/edit-aaccount');
  }


  return (
    <div>
      {/* <h1 className='heroText'>My Profile</h1> */}
      <div className='coverImageCont'>
          <img src={coverImage} className='coverImage' alt="" />
          <div className='profilePicture'>
              <img src={userProfPic} alt="" />
          </div>

          <div className='edtAccountCont'>
            <div className='notifCont'>
              <FontAwesomeIcon icon={faBell} />
              <p>0</p>
            </div>
            <button onClick={navigateToEditAcc}>Edit Profile</button>
          </div>
      </div>

      <div className='profDataCont'>
        <h2>{loggedInUser}</h2>
        <p>{userEmail}</p>
        <p>{userPhone}</p>
        
        <br /> <br />
        <hr />

        <div className='logoutCont' onClick={navigateToAccInfo}>
          <div>
            <img src={accInfo} alt="" />
            <p>Account Info</p>
          </div>
          <FontAwesomeIcon icon={faChevronRight} className="laftNavIcon"/>
        </div>

        <hr />

        <div className='logoutCont' onClick={logoutTheUser}>
          <div>
            <img src={logoutInfo} alt="" />
            <p>Logout</p>
          </div>
          <FontAwesomeIcon icon={faChevronRight} className="laftNavIcon"/>
        </div>
        
      </div>

      


    </div>
  )
}

export default ProfileComp