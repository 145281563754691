import React, { useState, useEffect } from 'react';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import axios from 'axios';
import './Rightbar.css'
import dashone from '../Images/dashone.svg'
import { getFirestore, collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import { app } from '../firebase.js';

function RightBar() {


  const db = getFirestore(app);
  const [weather, setWeather] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentDate, setCurrentDate] = useState('');
  const [currentTime, setCurrentTime] = useState('');
  const [city, setCity] = useState('Nairobi'); // State for the user's city
  const [upcomingTrips, setUpcomingTrips] = useState([]); 


  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyBJexYTRkvopO3_UwmLSG4nxTY7q_oDroY', // Add your Google Maps API key here
  });


  const darkTheme = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#212121"
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#212121"
        }
      ]
    },
    {
      "featureType": "administrative",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "administrative.country",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "administrative.locality",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#bdbdbd"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#181818"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#1b1b1b"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#2c2c2c"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#8a8a8a"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#373737"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#3c3c3c"
        }
      ]
    },
    {
      "featureType": "road.highway.controlled_access",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#4e4e4e"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#000000"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#3d3d3d"
        }
      ]
    }
  ];

  useEffect(() => {
    const fetchWeather = async (city) => {
      try {
        const response = await axios.get(
          'https://api.weatherapi.com/v1/current.json',
          {
            params: {
              key: 'ecd0603cbba44123b30161245240206',
              q: city,
              aqi: 'no'
            }
          }
        );
        setWeather(response.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    const updateTime = () => {
      const now = new Date();
      const options = { weekday: 'long', day: 'numeric', month: 'long' };
      const formattedDate = now.toLocaleDateString('en-US', options);
      const formattedTime = now.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
      setCurrentDate(formattedDate);
      setCurrentTime(formattedTime);
    };

    const getCityFromIP = async () => {
      try {
        const response = await axios.get('https://json.geoiplookup.io/');
        const city = response.data.city;
        setCity(city);
        fetchWeather(city); // Fetch weather for the detected city
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    updateTime();
    const intervalId = setInterval(updateTime, 60000); // Update the time every minute
    getCityFromIP(); // Get the user's location from IP

    return () => clearInterval(intervalId); // Clean up the interval on component unmount
  }, []);


  useEffect(() => {
    const fetchUpcomingTrips = async () => {
      try {
        const q = query(
          collection(db, 'Bookings'),
          where('status', '==', 'Pending Trip')
        );

        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const trips = [];
          querySnapshot.forEach((doc) => {
            trips.push(doc.data());
          });
          setUpcomingTrips(trips);
        } else {
          setUpcomingTrips([]); // No upcoming trips found
        }
      } catch (error) {
        console.error('Error fetching upcoming trips:', error);
      }
    };

    fetchUpcomingTrips();
  }, [db]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    // return <div>Error: {error.message}</div>;
  }

  return (
    <div className='rightbar'>
        
        <div className='dateCont1'>
          <div className='dateTimeCont'>
            <p>{currentDate}</p>
            <h4>{currentTime}</h4>
            <p>{city}</p>
          </div>

          <img src={weather.current.condition.icon} alt={weather.current.condition.text} />


          <div className='dateTimeCont'>
            <h4>{weather.current.temp_c}°C</h4>
            <p>{weather.current.condition.text}</p>
          </div>
        </div>

        <div className='dateCont'>
        {isLoaded ? (
        <GoogleMap
          mapContainerStyle={{ height: "400px", width: "100%" }}
          center={{ lat: -1.286389, lng: 36.817223 }} // Coordinates for Nairobi
          zoom={12}
          options={{
            styles: darkTheme,
            mapTypeControl: false,
            fullscreenControl: false,
            streetViewControl: false,
            zoomControl: false,
          }} 
          className="myNewMap"
        >
          <Marker position={{ lat: -1.286389, lng: 36.817223 }} />
        </GoogleMap>
      ) : (
        <div>Loading map...</div>
      )}
          
        </div>

        <div className='jetpoolingRequest'>
          <div className='jetCont'>
            <h6>Your next trip is on</h6>
            {upcomingTrips.length > 0 ? (
            <div>
              {upcomingTrips.map((trip, index) => (
                <div key={index}>
                  <p>Date: {trip.selectedDay} at {trip.selectedTime}</p>
                  {/* Add additional details as needed */}
                </div>
              ))}
            </div>
            ) : (
              <p>You don't have any <br/> upcoming trips</p>
            )}
            
          </div>
          <img src={dashone} alt="" />
        </div>


    </div>
  )
}

export default RightBar