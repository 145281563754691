import React from 'react'

function FavouritesComp() {
  return (
    <div>
      <h1 className='heroText'>Bookmarks</h1>

    </div>
  )
}

export default FavouritesComp