import React from 'react'
import LeftBar from '../Components/LeftBar'
import NavBar from '../Components/NavBar'
import './Dash.css'
import RightBar from '../Components/RightBar'
import ExpressComp from './ExpressComp'

function Express() {
  return (
    <div className='contentMain'> 
    <LeftBar/>
    <div className='theRightOne2'> 
      <ExpressComp/>  
   </div>
   {/* <RightBar/> */}
  </div>
  )
}

export default Express